<template>
  <el-dialog
    title="取消发货"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="630px"
  >
    <div class="offline_box">
      <div class="cancel_offline_note">
        <div>
          机型：{{row.phoneName}},
          串号：{{row.imei}},
          背贴码：{{row.barCode != '--' ? row.barCode : row.orderUnique}},
          <span style="color:red">发货单号：{{ row.expressNo }}</span>
        </div>
      </div>
      <p>是否确认取消发货该旧机，取消后订单会恢复未待发货状态，快递不会取消</p>
      <el-form>
        <el-form-item label="动态口令" size="medium" label-width="100px">
          <el-input v-model="command" placeholder="请输入谷歌动态验证码"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click.native="visible = false">取消</el-button>
      <el-button type="primary" @click.native="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'CancelDeliveryDia',
  data() {
    return {
      visible: false,
      row: {},
      command: ''
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.command = ''
      this.visible = true
    },
    confirm() {
      // const { orderNo, errorTrackFollowContent } = this
      if (!this.command) {
        this.$message.error('请填写口令')
        return
      }
      _api.cancelSend({ orderNo: this.row.orderNo, command: this.command }).then(() => {
        this.$message.success("操作成功");
        // 关闭弹窗
        this.visible = false;
        this.$emit('confirm')
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.offline_box {
  width: 100%;

  > h2 {
    color: #ff687b;
    font-size: 12px;
    font-weight: 900;
  }

  > p {
    font-size: 14px;
    margin: 20px 0;
    color: #333;
    text-align: center;
  }
  .cancel_offline_note{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f2f2;
    height: 55px;
  }
}
</style>