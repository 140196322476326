<template>
  <el-dialog
    title="催货跟进记录"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="800px"
  >
    <GlobalTable
      ref="GlobalTable"
      :columns="columns"
      :data="list"
      :maxHeight="700"
      :isPagination="false"
    >
      <!-- <template slot>
        <div style="display: flex;justify-content: space-between;height: 40px;">
          <div>催货上报时间：{{ errorTrackFollowTime }}</div>
          <div>催货完结时间：{{ errorTrackEndTime }}</div>
        </div>
      </template> -->
      <el-table-column label="催货反馈" slot="followContent" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.followContent != '--' || ''"
            class="item"
            effect="dark"
            :content="row.followContent"
            placement="top"
          >
            <span class="noteWrap">{{ row.followContent}}</span>
          </el-tooltip>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </GlobalTable>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" @click="visible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'FollowRecordDia',
  data() {
    return {
      visible: false,
      orderNo: '',
      list: [],

      columns:[
        { label:"跟进人",prop:"followAdminName"},
        { label:"跟进时间",prop:"createTime"},
        { slotName: "followContent" }, //	催货反馈              
      ]
    }
  },
  methods: {
    open(orderNo) {
      this.orderNo = orderNo
      this.getFollow()
      this.visible = true
    },
    getFollow(){
      _api.getFollow({orderNo: this.orderNo}).then((res) => {
        this.list = res.data || []
      });
    },
  }
}
</script>