<template>
  <el-dialog
    title="添加催货跟进"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="800px"
  >
    <el-form>
      <el-form-item label="跟进人：">{{ userName }}</el-form-item>
      <el-form-item label="跟进内容：">
        <el-input
          v-model="errorTrackFollowContent"
          type="textarea"
          :rows="6"
          placeholder="请输入跟进内容，最多500字"
          maxlength="500"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click.native="visible = false">取消</el-button>
      <el-button type="primary" @click.native="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'FollowAddDia',
  data() {
    return {
      visible: false,
      orderNo: '',
      userName: '',
      errorTrackFollowContent: ''
    }
  },
  created() {
    this.userName = localStorage.getItem("userName")
  },
  methods: {
    open(orderNo) {
      this.orderNo = orderNo
      this.errorTrackFollowContent = ''
      this.visible = true
    },
    confirm() {
      // const param = {
      //   orderNo: this.currentData.orderNo,
      //   errorTrackFollowContent:this.errorTrackFollowContent
      // };
      const { orderNo, errorTrackFollowContent } = this
      _api.doFollow({ orderNo, errorTrackFollowContent }).then((r) => {
        this.$message.success("操作成功");
        // 关闭弹窗
        this.visible = false;
        this.$emit('confirm')
      });
    },
  }
}
</script>