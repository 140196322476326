<!--tab卡片切换-->
<template>
  <div class="statusTabs">
    <div
      class="itemTab"
      v-for="(item, index) in tabData"
      :key="item.paramResult"
      :class="tabIndex == index ? 'tabActive' : ''"
      @click="tabAction(index)"
    >
      <div class="itemName">
        <span class="name">{{ item.text }}</span>
        <i class="el-icon-warning-outline" @mouseenter="item.isHover = true" @mouseleave="item.isHover = false">
          <div v-show="item.isHover" class="popNotion">
           <div v-html="item.notion"></div>
          </div>
        </i>
      </div>
      <div class="itemCount">
        <span class="num">{{ item.num > 9999 ? "9999+" : item.num }}</span>
        <!-- <span v-if="item.isShowNum" class="num">{{ item.num > 9999 ? "9999+" : item.num }}</span>
        <span v-else class="num">***</span> -->
        <span class="unit">{{ unitName }}</span>
      </div>
      <div class="itemUn">
        <span>{{ item.name }}</span>&nbsp;
        <span class="num">{{ item.unNum > 9999 ? "9999+" : item.unNum }}</span>
        <!-- <span v-if="item.isShowNum" class="num">{{ item.unNum > 9999 ? "9999+" : item.unNum }}</span>
        <span v-else class="num">***</span> -->
        <span class="unit">{{ unitName }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TabStatusSelect",
  props: {
    // 初始选择索引
    activeIndex: {
      type: Number,
      default: 0,
    },
    unitName: {
      type: String,
      default: "",
    },
    // 数据
    tabData: {
      // 数组项text代表文本，num代表数量
      type: Array,
      // 数组需避免引用更新
      default: () => [],
      // 必传
      require: true,
    },
  },
  // 初始钩子
  created() {},
  watch: {
    activeIndex(val) {
        this.tabIndex = val;
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    tabAction(newTab) {
      this.tabIndex = newTab;
      this.$emit("tabAction", newTab);
    },
  },
};
</script>
<style lang="scss" scoped ref="stylesheet/scss">
.statusTabs {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  color: #535455;
  padding: 10px;
  .itemTab {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 130px;
    height: 140px;
    padding: 10px;
    background: #f1f7ff;
    border: 2px solid #f0f0f0;
    box-shadow: 0px 4px 5px 0px rgba(76, 132, 255, 0.4);
    border-radius: 6px;
    margin-right: 15px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    .itemName{
      display: flex;
      .el-icon-warning-outline{
        display: flex;
        align-items: center;
        margin-left: 2px;
        position: relative;
        .popNotion{
          position: absolute;
          left: 25px;
          top: -35px;
          z-index: 999;
          width: 250px;
          min-height: 70px;
          padding: 10px;
          border-radius: 5px;
          font-size: 12px;
          background-color: #fff;
          color: #333;
          border: 1px solid #EAEAEA;
          box-shadow: -6px 9px 30px 0px rgba(168,168,168,0.36);
          &::after{
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: -8px;
            top: 39px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 8px solid #fff;
          }
        }
      }

    }
    .itemCount {
      color: #4c84ff;
      .num {
        font-family: PingFang-SC-Heavy;
        font-size: 25px;
        height: 25px;
        line-height: 25px;
        font-weight: 700;
      }
      .unit {
        font-size: 16px;
      }
    }
    .itemUn {
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      color: #ff7284;
    }
  }
  .tabActive {
    border: 2px solid #73aaff;
  }
}
</style>
